// REACT IMPORTS
import React, { useState } from "react";
// BOOTSTRAP FOR CSS
import { Container, Row, Col } from "react-bootstrap";
// CSS FOR ANIMATION
import "animate.css";
// TRACKVISIBILITY FOR CHILD COMPONENT
import TrackVisibility from "react-on-screen";
// AXIOS FOR FETCHING DATA
import axios from "axios";

export const Contact = () => {
  // FORM DETAILS GETTING STORED
  const formInitialDetails = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Send");
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    // Call your backend with axios
    axios
      .post("../php/form-process.php", formDetails)
      .then((response) => {
        setStatus({
          message: "Message sent successfully!",
          success: true,
        });
      })
      .catch((error) => {
        setStatus({
          message: "Something went wrong. Please try again later.",
          success: false,
        });
      });
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <TrackVisibility>
            {({ isVisible }) => (
              <div
                className={isVisible ? "animate__animated animate__fadeIn" : ""}
              >
                <h2>Get In Touch</h2>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} sm={6} className="px-1">
                      <input
                        type="text"
                        name="firstName" // Add name attribute
                        value={formDetails.firstName}
                        placeholder="First Name"
                        onChange={(e) =>
                          onFormUpdate("firstName", e.target.value)
                        }
                      />
                    </Col>
                    <Col xs={12} sm={6} className="px-1">
                      <input
                        type="text"
                        name="lastName" // Add name attribute
                        value={formDetails.lastName}
                        placeholder="Last Name"
                        onChange={(e) =>
                          onFormUpdate("lastName", e.target.value)
                        }
                      />
                    </Col>
                    <Col xs={12} sm={6} className="px-1">
                      <input
                        type="email"
                        name="email" // Add name attribute
                        value={formDetails.email}
                        placeholder="Email Address"
                        onChange={(e) => onFormUpdate("email", e.target.value)}
                      />
                    </Col>
                    <Col xs={12} sm={6} className="px-1">
                      <input
                        type="tel"
                        name="phone" // Add name attribute
                        value={formDetails.phone}
                        placeholder="Phone No."
                        onChange={(e) => onFormUpdate("phone", e.target.value)}
                      />
                    </Col>
                    <Col xs={12} className="px-1">
                      <textarea
                        rows="6"
                        name="message" // Add name attribute
                        value={formDetails.message}
                        placeholder="Message"
                        onChange={(e) =>
                          onFormUpdate("message", e.target.value)
                        }
                      ></textarea>
                      <button
                        type="submit"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <span>{buttonText}</span>
                      </button>
                    </Col>
                    {status.message && (
                      <Col>
                        <p
                          className={
                            status.success === false ? "danger" : "success"
                          }
                        >
                          {status.message}
                        </p>
                      </Col>
                    )}
                  </Row>
                </form>
              </div>
            )}
          </TrackVisibility>
        </Row>
      </Container>
    </section>
  );
};
